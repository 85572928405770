export const STRINGS = {
  units: {
    prest: ["经验", ""],
    time: ["时间", ""],
    //  Materials
    f: ["食物", "食物用于维持所有单位。"],
    w: ["土壤", "土壤用于构建更好的巢。"],
    c: ["水晶", "水晶是用来生产科学。"],
    s: ["科学", "科学是用来发现新事物的。"],
    //  Ants
    l: ["幼虫", "幼虫"],
    q: ["女王", "女王"],
    n: ["巢穴", "巢穴"],
    //  Ghantering
    e: ["雄蜂", "雄蜂收集食物。"],
    h: ["地质学家", "地质学家会寻找水晶。"],
    i: [
      "学生",
      [
        "学生将尝试研究科学。",
        "学生需要喝水晶。"
      ]
    ],
    //  Workers
    a: [
      "农民",
      [
        "水培农民使用晶体在没有土壤的情况下种植真菌。",
        "蚂蚁是世界上最好的真菌种植户。",
        "公元前10000年开始耕种。"
      ]
    ],
    b: ["木匠", "木匠蚂蚁在土壤中筑巢。"],
    m: [
      "矿工",
      [
        "矿工通过采矿提取水晶。",
        "不出所料，又是一个关于矿工的放置游戏。"
      ]
    ],
    d: [
      "科学家",
      [
        "科学家收集的科学。",
        "科学家需要饮用水晶。",
        "科学家和学生一样,但他们更厉害。"
      ]
    ],
    // Buildings
    aG: ["水培农场", "一个水培农场。"],
    bG: ["土壤营地", "土壤营地是用来获得更多的土壤。"],
    mG: ["水晶矿", "一个水晶矿。"],
    dG: ["大学", "大学产生毕业的科学家。"],
    //  Engineers
    aGG: ["水利工程", "水利工程师对农场有加成。"],
    bGG: ["土壤工程师", "土壤工程师生产土壤营地。"],
    mGG: ["矿山工程师", "矿山工程师生产矿山。"],
    dGG: ["教育部门", "教育部门生产大学。"],
    //  World Bonus
    1: ["食物生产加成", ""],
    2: ["土壤生产加成", ""],
    3: ["水晶生产加成", ""],
    4: ["科学生产加成", ""],
    5: ["杀敌加成", ""],
    larvaBon: ["幼虫生产加成", ""],
    queenBon: ["女王生产加成", ""],
    //  Malus
    Mf1: ["有毒真菌", "有毒真菌"],
    Mf2: ["古老的真菌", "古老的真菌"],
    Mf3: ["僵尸真菌", "僵尸真菌"],
    Mw1: ["食虫植物", ""],
    Mw2: ["捕蝇纸陷阱", ""],
    Mw3: ["猪笼草", ""],
    Mc1: ["小螨虫", ""],
    Mc2: ["螨虫", ""],
    Mc3: ["寄生螨虫", ""],
    Ms1: ["僵尸真菌孢子", ""],
    Ms2: ["僵尸真菌", ""],
    Ms3: ["僵尸真菌菌落 ", ""],
    //  Killers
    F: ["消毒蚂蚁", "消灭有毒真菌."],
    W: ["火蚂蚁", ["燃烧食肉植物", "火蚁确实存在."]],
    C: ["黑色花园蚂蚁", "杀死螨虫."],
    S: ["黄色疯狂蚂蚁", "杀死僵尸真菌."],
    HB: ["常规", "+10% 杀敌."],
    HQ: ["总部", "+10% 杀敌."],
    //  Mastery Bonus
    scieMast: ["科学专精"],
    fooMast: ["食物专精"],
    wooMast: ["土壤专精"],
    cryMast: ["水晶专精"],
    harvMast: ["收获专精"],
    matMast: ["材料专精"],
    armyMast: ["军队专精"],
    //  Supply
    fS: ["食物供应", "永不停息的食物供应"],
    wS: ["土壤供应", "永不停息的土壤供应"],
    cS: ["水晶供应", "永不停息的水晶供应"],
    //  Bee
    Z: ["觅食蜜蜂", "觅食蜜蜂"],
    cb: ["木匠蜜蜂", "木匠蜜蜂"],
    sb: ["学生蜜蜂", "学生蜜蜂"],
    Q: ["蜜蜂幼虫", "蜜蜂幼虫"],
    P: ["蜜蜂女王", "蜜蜂女王"],
    N: ["蜜蜂巢穴", "蜜蜂巢穴"],
    A: ["蜜蜂农民", "种植鲜花"],
    B: ["蜜蜂木匠", "木匠蜜蜂"],
    E: ["蜜蜂矿工", "矿工蜜蜂"],
    D: ["蜜蜂科学家", "科学家蜜蜂"],
    AG: ["鲜花农场", "种满鲜花的农场."],
    BG: ["蜜蜂土壤营地", "蜜蜂土壤营地"],
    EG: ["蜜蜂矿山", "蜜蜂矿山"],
    DG: ["蜜蜂大学", "蜜蜂大学"],
    AGG: ["花匠工程师 ", "花匠工程师生产鲜花农场."],
    BGG: ["蜜蜂土壤工程师", "蜜蜂土壤工程师生产蜜蜂土壤营地."],
    EGG: ["蜜蜂矿山工程师", "蜜蜂矿山工程师生产蜜蜂矿山."],
    DGG: ["蜜蜂教育部门", "蜜蜂教育部门."],
    //  Wasp
    x: ["觅食黄蜂", "觅食黄蜂"],
    hw: ["大黄蜂", ["大黄蜂", "一只服用类固醇的黄蜂。"]],
    sw: ["聪明的黄蜂", "聪明黄蜂"],
    y: ["黄蜂幼虫", "黄蜂幼虫"],
    z: ["黄蜂女王", "黄蜂女王"],
    r: ["黄蜂巢穴", "黄蜂巢穴"],
    v: ["黄蜂农民", "一个农民黄蜂."],
    V: ["黄蜂木匠", "一个木匠黄蜂."],
    o: ["黄蜂矿工", "一个矿工黄蜂."],
    p: ["黄蜂科学家", "一个科学家黄蜂."],
    vG: ["黄蜂农场", "黄蜂农场."],
    VG: ["黄蜂土壤营地", "黄蜂土壤营地."],
    oG: ["黄蜂矿山", "黄蜂矿山."],
    pG: ["黄蜂大学", "黄蜂大学"],
    vGG: ["黄蜂水利工程师", "黄蜂水利工程师生产黄蜂农场"],
    VGG: ["黄蜂土壤工程师", "黄蜂土壤工程师生产黄蜂土壤营地"],
    oGG: ["黄蜂矿山工程师", "黄蜂矿山工程师生产黄蜂矿山"],
    pGG: [
      "黄蜂教育部门",
      "黄蜂教育部门生产黄蜂大学。"
    ],
    //  Helpers
    lf: ["切叶蚁", "改善农业。"],
    hu: ["猎人", ""],
    mw: ["主要工人", ""],
    mh: ["大矿工", "改善采矿。"],
    sh: ["大木匠", "改善土壤收集。"]
  },
  researches: {
    t: ["团队合作", "在手动购买的基础上获得生产加成。"],
    T: ["团队合作2", "提高团队合作的加成"], // upgradable ?
    W: ["双胞胎", "同样的价格购买更多的单位"],
    r: ["旅行", "前往一个新的世界!"],
    M: ["专精", "获得1个专精点数"],
    h: ["收获", "+100% 来自采集者的资源。"],
    1: ["1小时 扭曲", "免费的时间扭曲."],
    2: ["2小时 扭曲", "免费的时间扭曲."],
    3: ["3小时 扭曲", "免费的时间扭曲."],
    SP: ["产卵", "+50% 幼虫产量."],
    ont: [
      "极端",
      "+10% 世界等级和经验;+20% 需求; 增加随机敌人，以及提高敌人;如果当前世界等级高于15并且高于50%最大等级, 在旅行时会给予你1个专精点"
    ],
    //  Workers
    wo: ["更好的工人", "+30% 来自工人的资源产量."],
    we: ["高效的工人", "+10% 资源获得。"],
    w: ["工人", "工人"],
    a: [
      "农民",
      "水培农民使用水晶在没有土壤的情况下种植真菌。"
    ],
    b: ["木匠", "木匠蚂蚁建造由画廊组成的巢."],
    m: ["矿工", "矿工"],
    d: ["科学家", "科学家"],
    scie1: ["科学方法", "+50% 科学产量"],
    // Buildings
    u: ["建筑", "建筑生产工人."],
    aG: ["水培农场", "水培真菌农场."],
    bG: ["土壤营地", "肥沃的土壤源."],
    mG: ["矿山", "一个水晶矿."],
    dG: ["大学", "大学培养科学家."],
    //  Engineers
    e: ["工程师", "工程师建造建筑物."],
    aGG: ["水利工程师", "水利工程师生产农场."],
    bGG: ["土壤工程师", "土壤工程师生产土壤营地."],
    mGG: ["矿山工程师", "矿山工程师生产矿山."],
    dGG: ["教育部门", "教育部门生产大学"],
    //  Bee
    A: ["蜜蜂农民", "种植鲜花."],
    B: ["蜜蜂木匠", "木匠蜜蜂."],
    E: ["蜜蜂矿工", "矿工蜜蜂."],
    D: ["蜜蜂科学家", "科学家蜜蜂."],
    AG: ["鲜花农场", "种满鲜花的农场."],
    BG: ["蜜蜂土壤营地", "蜜蜂土壤营地"],
    EG: ["蜜蜂矿山", "蜜蜂矿山"],
    DG: ["蜜蜂大学", "蜜蜂大学"],
    AGG: ["花匠工程师 ", "花匠工程师生产鲜花农场"],
    BGG: ["蜜蜂土壤工程师", "蜜蜂土壤工程师生产蜜蜂土壤营地."],
    EGG: ["蜜蜂矿山工程师", "蜜蜂矿山工程师生产蜜蜂矿山."],
    DGG: ["蜜蜂教育部门", "蜜蜂教育部门."],
    // Wasp
    v: ["黄蜂农民", "一个农民黄蜂."],
    V: ["黄蜂木匠", "一个木匠黄蜂."],
    o: ["黄蜂矿工", "一个矿工黄蜂."],
    p: ["黄蜂科学家", "一个科学家黄蜂."],
    vG: ["黄蜂农场", "黄蜂农场."],
    VG: ["黄蜂土壤营地", "黄蜂土壤营地."],
    oG: ["黄蜂矿山", "黄蜂矿山."],
    pG: ["黄蜂大学", "黄蜂大学"],
    vGG: ["黄蜂水利工程师", "黄蜂水利工程师生产黄蜂农场."],
    VGG: ["黄蜂土壤工程师", "黄蜂土壤工程师生产黄蜂土壤营地."],
    oGG: ["黄蜂矿山工程师", "黄蜂矿山工程师生产黄蜂矿山."],
    pGG: [
      "黄蜂教育部门",
      "黄蜂教育部门生产黄蜂大学."
    ],
    //  Super Major
    sm: ["主宰助手", ""],
    lf: ["切叶蚁", "改善农业。"],
    mh: ["大矿工", "改善采矿。"],
    sh: ["大木匠", "改善土壤收集。"],
    //  Kill World
    aow: ["战争的艺术", "杀敌提高10%"]
  },
  worlds: {
    "1Pre": "炽热的",
    "2Pre": "树木繁茂的",
    "3Pre": "结晶的",
    "4Pre": "科学的",
    "1Suf": "食物的",
    "2Suf": "土壤的",
    "3Suf": "水晶的",
    "4Suf": "科学的",
    fBio: "农田",
    wBio: "森林",
    cBio: "矿山",
    sBio: "大学",
    fSPre: "食品供应",
    wSPre: "土壤供应",
    cSPre: "水晶供应",
    fSSuff: "食物",
    wSSuff: "土壤",
    cSSuff: "水晶",
    //  Bee
    beePre: "蜜蜂的",
    beeBio: "蜂巢",
    beeSuff: "蜜蜂的",
    //  Wasp
    waspPre: "黄蜂",
    waspBio: "巢穴",
    waspSuff: "黄蜂的",
    //  Super Major
    majorPre: "主宰",
    majorSuff: "主宰的",
    //  Malus Bonus
    mkPre: "充满敌意的",
    mkBio: "战场",
    mkSuff: "战争的",
    //  Larva
    larvaPre: "蜂群",
    larvaSuff: "幼虫",
    //  Queen
    queenPre: "高贵的",
    queenSuff: "女王"
  },
  prestige: {
    //  Followers 1
    e: ["雄蜂追随者", "开始新的世界初始有更多5个雄蜂"],
    h: ["地质学家追随者", "开始新的世界初始有更多5个地质学家"],
    i: ["学生追随者", "开始新的世界初始有更多5个学生"],
    //  Followers 2
    a: ["农民追随者", "开始新的世界初始有更多5个农民"],
    b: ["木匠追随者", "开始新的世界初始有更多5个木匠"],
    m: ["矿工追随者", "开始新的世界初始有更多5个矿工"],
    d: ["科学家追随者", "开始新的世界初始有更多5个科学家"],
    //  Time
    P: ["时间生产者", "+0.1 时间/秒"],
    B: ["时间银行", "+1 最大小时"],
    //  AutoBuy
    H: ["孵化自动购买者", "针对所有单位解锁孵化自动购买者"],
    t: ["团队自动购买者", "针对所有单位解锁团队自动购买者"],
    T: ["双胞胎自动购买者", "针对所有单位解锁双胞胎自动购买者"],
    M: ["时间扭曲自动购买者", "解锁一分钟时间扭曲自动购买者"],
    R: ["研究自动购买者", "解锁研究自动购买者"],
    //  Technology
    F: ["农业", "+10% 食物产量"],
    C: ["木匠", "+10% 土壤产量"],
    n: ["采矿", "+10% 水晶产量"],
    s: ["学习", "+10% 科学产量"],
    //  Team
    E: ["团队合作", "+30% 团队合作加成"],
    //  Worlds
    w: ["更好的世界", "+10% 世界加成"],
    r: ["更好的军队", "+10% 军队杀人物资"],
    L: ["最高等级", "+10% 最高世界等级"],
    //  Swarm
    sl: ["幼虫加成", "+10% 幼虫产量"],
    sq: ["女王加成", "+10% 女王产量"]
  },
  actions: {
    warp60: ["扭曲分钟", "时间扭曲以分钟计算"],
    warp3600: ["扭曲小时", "时间扭曲以小时计算"]
  },
  bug: {
    0: "蚂蚁",
    1: "蜜蜂",
    2: "黄蜂",
    3: "主宰"
  }
};
