import * as positions from "./positions.json";

export enum MasteryTypes {
  MORE_FOLLOWERS = 0,
  MORE_IDLE_8H,
  FOOD_BONUS,
  SCIENCE_BONUS,
  CRYSTAL_BONUS,
  SOIL_BONUS,
  HARVEST_BONUS,
  MATERIAL_GAIN,
  MORE_FOLLOWERS_GA,
  MORE_FOLLOWERS_WO,
  TIME_GEN,
  TIME_BANK,
  TEAM_START,
  TEAM_PRESTIGE,
  SCIENTIFIC_METHOD,
  TECHNOLOGY_PRESTIGE,
  START_TWIN,
  FREE_WARP_RES,
  TIME_GEN_AND_BANK,
  START_RESEARCHS,
  DOUBLE_PREFIX,
  DOUBLE_SUFFIX,
  WORLD_LEVEL,
  WORLD_LEVEL_PRESTIGE,
  BETTER_WORLD,
  BETTER_WORLD_EXPERIENCE,
  BETTER_HELPERS,
  DOUBLE_ARMY
}
export const notable = [
  MasteryTypes.MATERIAL_GAIN,
  MasteryTypes.START_TWIN,
  MasteryTypes.FREE_WARP_RES,
  MasteryTypes.TIME_GEN_AND_BANK,
  MasteryTypes.START_RESEARCHS,
  MasteryTypes.DOUBLE_PREFIX,
  MasteryTypes.DOUBLE_SUFFIX,
  MasteryTypes.WORLD_LEVEL,
  MasteryTypes.DOUBLE_ARMY
];

export class Mastery {
  static readonly normalColor = "#4286f4dd";
  static readonly availableColor = "#008000dd";
  static readonly ownedColor = "#ff0000dd";
  static readonly notableColor = "#ffa500dd";

  static getDescription(type: MasteryTypes, num = 1): string {
    let ret = "";
    switch (type) {
      case MasteryTypes.MORE_FOLLOWERS: {
        ret = "+" + 100 * num + "%\n更多的追随者";
        break;
      }
      case MasteryTypes.MORE_IDLE_8H: {
        ret = "+" + 30 * num + "% 空闲时间\n在8小时后";
        break;
      }
      case MasteryTypes.SCIENCE_BONUS: {
        ret = "+" + 10 * num + "%\n科学";
        break;
      }
      case MasteryTypes.FOOD_BONUS: {
        ret = "+" + 10 * num + "%\n食物";
        break;
      }
      case MasteryTypes.SOIL_BONUS: {
        ret = "+" + 10 * num + "%\n土壤";
        break;
      }
      case MasteryTypes.CRYSTAL_BONUS: {
        ret = "+" + 10 * num + "%\n水晶";
        break;
      }
      case MasteryTypes.HARVEST_BONUS: {
        ret = "+" + 20 * num + "% 资源\n来自采集者";
        break;
      }
      case MasteryTypes.MATERIAL_GAIN: {
        ret = "+" + 10 * num + "%\n材料获得";
        break;
      }
      case MasteryTypes.MORE_FOLLOWERS_GA: {
        ret = "+" + 300 * num + "% 的\n采集者追随者";
        break;
      }
      case MasteryTypes.MORE_FOLLOWERS_WO: {
        ret = "+" + 300 * num + "% 的\n工人追随者";
        break;
      }
      case MasteryTypes.TIME_GEN: {
        ret = "+" + 30 * num + "%\n时间 /秒";
        break;
      }
      case MasteryTypes.TIME_BANK: {
        ret = "+" + 50 * num + "%\n 时间银行";
        break;
      }
      case MasteryTypes.TIME_GEN_AND_BANK: {
        ret = "+" + 200 * num + "%\n 时间 /秒 和时间银行";
        break;
      }
      case MasteryTypes.TEAM_START: {
        ret = "开始新世界时默认就有\n" + 1 * num + " 团队升级";
        break;
      }
      case MasteryTypes.TEAM_PRESTIGE: {
        ret = "团队声望升级\n" + 100 * num + "% 更高效率";
        break;
      }
      case MasteryTypes.SCIENTIFIC_METHOD: {
        ret = "科学方法研究\n" + 100 * num + "% 更高效率";
        break;
      }
      case MasteryTypes.TECHNOLOGY_PRESTIGE: {
        ret = "技术经验升级\n" + 100 * num + "% 更高效率";
        break;
      }
      case MasteryTypes.START_TWIN: {
        ret = "开始新世界时默认就有\n" + 1 * num + " 双胞胎升级";
        break;
      }
      case MasteryTypes.FREE_WARP_RES: {
        ret = "开始新世界时默认就有 \n免费扭曲 (1小时 2小时 3小时)";
        break;
      }
      case MasteryTypes.START_RESEARCHS: {
        ret = "开始新世界时默认就有\n4 科学方法和收获";
        break;
      }
      case MasteryTypes.DOUBLE_PREFIX: {
        ret = "30% 几率出现\n双重前缀";
        break;
      }
      case MasteryTypes.DOUBLE_SUFFIX: {
        ret = "30% 几率出现\n双重后缀";
        break;
      }
      case MasteryTypes.WORLD_LEVEL: {
        ret = "减少世界旅行需求";
        break;
      }
      case MasteryTypes.BETTER_WORLD: {
        ret = "+" + 20 * num + "%\n世界加成";
        break;
      }
      case MasteryTypes.BETTER_WORLD_EXPERIENCE: {
        ret = "更好的世界声望升级\n50% 更高效率";
        break;
      }
      case MasteryTypes.WORLD_LEVEL_PRESTIGE: {
        ret = "最高等级声望升级\n50% 更高效率";
        break;
      }
      case MasteryTypes.BETTER_HELPERS: {
        ret = "+100% 加成\n来自助手";
        break;
      }
      case MasteryTypes.DOUBLE_ARMY: {
        ret = "+200%\n军队杀敌加成";
        break;
      }
    }
    return ret;
  }

  label = "";
  color = "blue";
  available = false;
  owned = false;

  constructor(
    public id: number,
    public type: MasteryTypes,
    public x = 0,
    public y = 0
  ) {
    this.label = Mastery.getDescription(type);
    this.color = notable.find(n => n === this.type)
      ? Mastery.notableColor
      : Mastery.normalColor;

    if (this.id in positions) {
      this.x = positions[this.id].x;
      this.y = positions[this.id].y;
    }
  }
}
